<template>  
<div class="common-layout"  >
    <el-container v-if="showMenu" >
		  <el-aside width="15%" >   
			<el-row class="tac"> 
			<div class="outer-container">
			  <div class="inner-container">
			    <div class="content">
				<el-col :span="24"> 
				  <el-menu
					active-text-color="#ffd04b"
					background-color="#545c64"
					class="el-menu-vertical-demo"
					default-active="2"
					text-color="#fff"
					:router="menurouter" 
					:default-openeds="defaultOpeneds"
					@open="handleOpen"
					@close="handleClose"
				  >
				  <div v-for="column in  columns">
					  <el-sub-menu :index="column.name" >
						 <template #title> 
						   <component :is="column.icon" style="width: 16px;height: 16px;margin-right: 5px;"></component> 
						   <span>{{column.name}}</span>
						 </template>
							  <div v-for="site in column.children">
								  <el-menu-item :index="site.backurl+'?columnid='+site.id"  >   
									 <component :is="site.icon" style="width: 16px;height: 16px;margin-right: 5px;"></component> 
									   {{ site.name }}
								  </el-menu-item>
							  </div>
					   </el-sub-menu> 
					</div> 
				  <el-sub-menu index="4">
					<template #title>
					  <el-icon><Tools /></el-icon>
					  <span>发现</span>
					</template>  
						<el-menu-item index="/setupIndex"><el-icon><Tools /></el-icon>首页</el-menu-item> 
						<el-menu-item index="/"  @click="outlogin()"><el-icon><Tools /></el-icon>退出登录</el-menu-item> 
				  </el-sub-menu>  
				  </el-menu>
				  <!-- </el-scrollbar> -->
				</el-col>
				
				   </div>
			     </div>
				</div> 
			  </el-row>  
		  </el-aside>
      <el-container> 
        <el-main> 
			 <router-view v-slot="{ Component }">
			     <keep-alive  v-if="isRouterAlive" >
			       <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/>
			     </keep-alive>
			       <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>
			   </router-view> 
				 
		</el-main>
		  <!-- <el-footer> 
			<Bottom></Bottom>
		  </el-footer> -->
      </el-container>
    </el-container>
	 <el-container v-else class="container">
		   <router-view />
	 </el-container>
  </div>  
</template>

<script >
 import { reactive, ref, toRefs, onMounted,onUnmounted,nextTick,provide} from 'vue' 
 import { useRouter } from 'vue-router' 
 import {Document,Menu,Location,Setting,Crop} from '@element-plus/icons-vue'
 import { getLocalStorage } from '@/utils'
 import { removeLocalStorage } from '@/utils/index'
 import Bottom from '@/components/Bottom.vue'
export default {
  name: "App" ,
  components: { 
	//Footer
	 Bottom
  },
   // data() {
		 // return {
		 //     defaultOpeneds: ['商品'], // 默认打开的栏目
		 //     data: {
			//   id: 1 
			//  },  
		 //     menurouter:true,
		 // }
   // }, 
  setup() 
  {   
     const isRouterAlive = ref(true);// 局部组件刷新
	 const noMenu = ['/login']
     const router = useRouter()
     const reac = reactive({ 
       showMenu:true,
	   columns:[],
	   defaultOpeneds:[], // 默认打开的栏目
	   menurouter:true, 
    })
	
	const routerBefore = router.beforeEach((to, from, next) => {   
	  if (to.path == '/login') {   
	       next()
	  } 
	  else 
	  { 
		  var loginoutput = getLocalStorage('loginoutput')  
		   if(loginoutput!=null){
			   reac.columns = loginoutput.columns.children 
				 
		   }
		   else{
		   		 router.push({ path: '/login'})  
		    }
		   next() 
	  }
	   reac.showMenu = !noMenu.includes(to.path) 
	})
	
	// 局部组件刷新
	 const reload = () => {
	      isRouterAlive.value = false;
	      nextTick(() => {
	         isRouterAlive.value = true;
	      });
	    };
	    provide("reload", reload);
	// 局部组件刷新 end 
	
	onUnmounted(() => {
	  routerBefore()
	})
	const outlogin = () => {
		   removeLocalStorage('loginoutput')
		   router.push({ path: '/login'})  
	} 
	const handleOpen = (index, indexPath) => {
		   // 确保只有当前打开的菜单保持在数组中 
		   reac.defaultOpeneds = [index];  
	} 
	const handleClose = (index, indexPath) => { 
		  // 移除关闭的菜单的 index 
	      reac.defaultOpeneds = []; 
	} 
	return {
	  ...toRefs(reac), 
	  isRouterAlive,
	  outlogin,
	  handleOpen,
	  handleClose, 
	}
  } 
};
</script>

<style scoped> 
	.container {
	 /* margin-top: 100px; */
	}  
	.el-main { 
	   min-height:690px;
	   padding: 0px;
	 } 
	.el-footer { 
		 font-size: 12px;  
		 height: 30px;
		 display: flex; 
		 color:#999; 
		 align-items: center;
		 justify-content: center;
      }
	  /* .menu-scrollbar {
	    height: 100%;  
	  } */
	  
	  .content {
	      padding: 0px;
	  }
	   
	  .outer-container  {
	      margin: 0 auto;
	  }
	  .content, .outer-container {
	    width: 200px;
	    height:700px;
	  }
	   
	  .outer-container {
	    /* border: 1px solid #ddd; */
	    position: relative;
	    overflow: hidden;
	  }
	   
	  .inner-container {
	    position: absolute;
	    left: 0;
	    overflow-x: hidden;
	    overflow-y: scroll;
	  }
	   
	  .inner-container::-webkit-scrollbar {
	    display: none; 
	 }
</style>
 
